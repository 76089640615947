.sel-options {
  .select {
    min-width: initial;
    padding: 1px 22px 1px 0;
    color: white;
  }

  .icon {
    color: white;
  }
}