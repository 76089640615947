@import "../../../assets/styles/colors";

.niquel-header {
  .btn-back {
    color: white;
    opacity: .7;
    cursor: pointer;

    &:hover {
      transition: opacity 200ms;
      opacity: 1;
    }
  }

  .header-btn {
    &:hover {
      background-color: rgba(0, 0, 0, .08);
    }

    &, &:active {
      box-shadow: none;
    }

    .username {
      margin-left: 2px;
    }
  }

  .header-content {
    min-width: 15rem;
    padding: 0.5rem 1rem;
  }

  .server-btn {
    @extend .header-btn;
    &:hover {
      background-color: rgba(0, 150, 136, 1);
      cursor: default;
    }
  }
}
