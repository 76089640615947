@import '../../assets/styles/colors';

.niquel-login {
  height: 100vh;

  .login-title {
    background: $primary-main;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .title {
      color: #ffffff;
      font-size: 80px;
    }

    .subtitle {
      color: rgba(255, 255, 255, .6);
      font-size: 28px;
      text-align: center;
    }
  }

  .login-form {
    height: 100vh;
    justify-content: center;
    display: flex;

    .login-form-wrapper {
      display: flex;
      flex-direction: column;
      width: 24rem;
      justify-content: center;

      .form {
        padding: 1.5rem 0;
      }
    }

    .footer-disclaimer {
      position: absolute;
      bottom: 1rem;
      font-size: 1rem;
      color: #6c757d;
    }
  }
}
