$table-row-hover: rgba(0, 0, 0, .05);

$dark-gray: #4a4a4a;
$background-paper: rgba(255, 255, 255, 1);
$background-default: #fafafa;

$primary-light: rgba(178, 223, 219, 1);
$primary-main: rgb(0, 150, 136);
$primary-dark: rgba(0, 121, 107, 1);

$secondary-light: rgba(247, 215, 163, 1);
$secondary-main: rgb(239, 174, 73);
$secondary-dark: rgb(241, 161, 38);

$error-light: #e57373;
$error-main: #f44336;
$error-dark: #d32f2f;

$text-primary: rgba(33, 33, 33, 1);
$text-secondary: rgba(117, 117, 117, 1);
$text-disabled: rgba(0, 0, 0, 0.38);
$text-hint: rgba(0, 0, 0, 0.38);
