.niquel-loading-wrapper {
  z-index: 1301;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  display: block;

  .niquel-loading-icon {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .spinner {
    animation: rotate 3s ease-in-out infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;

    & .path {
      stroke: rgba(0, 150, 136, 1);
      stroke-linecap: round;
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
      animation: dash 3s ease-in-out infinite;
    }

  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(460deg);
    }
    50% {
      transform: rotate(830deg);
    }
    to {
      transform: rotate(1080deg);
    }
  }

  @keyframes dash {
    from {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    to {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
}