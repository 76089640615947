@import 'niquel-loading';
@import "colors";

.niquel-main {
  width: 100%;
  margin-top: 3rem;
  min-height: calc(100vh - 3rem);
  background-color: $background-default;
}

.text-dark-gray {
  color: $dark-gray !important;
}

.text-light {
  opacity: 0.7;
}

.niquel-scroll {
  overflow: hidden;

  &:hover {
    overflow-y: overlay;
  }

  &::-webkit-scrollbar {
    width: 8px
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00)
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(190, 193, 197);
    border: 2px solid #262f3d;
    border-radius: 8px
  }
}

.niquel-fab-btn {
  position: fixed !important;
  right: 1.5rem;
  bottom: 1.5rem;
  z-index: 1202;

  &:hover {
    z-index: 9999;
  }
}

.niquel-fab-top-btn {
  @extend .niquel-fab-btn;
  top: 4rem;
  z-index: 1200;
  height: 56px;
}

.niquel-fab-top-subheader-btn {
  @extend .niquel-fab-btn;
  top: 4.5rem;
}

.niquel-subfab-btn {
  @extend .niquel-fab-btn;
  bottom: 6rem;
}

.background-error {
  background-color: $error-main !important;
}

.border-error {
  border-color: $error-main !important;
}

.background-secondary-light {
  background-color: rgba(245, 177, 73, .1);
}

.background-secondary {
  background-color: $secondary-main;
  color: $white;
}

.hover-error {
  &:hover {
    background-color: rgba(211, 47, 47, 0.08) !important;
  }
}

.color-error {
  color: $error-main !important;
}

.MuiToolbar-regular {
  border-bottom: 0 !important;
}

.niquel-dialog-full-screen {
  //padding-left: 56px;
  z-index: 1202;
}

.niquel-dialog-green {
  background-color: $primary-main !important;
}

.niquel-dialog-yellow {
  background-color: $secondary-dark !important;
}

.niquel-dialog-red {
  background-color: $error-main !important;
}

.niquel-date-input {
  width: 6rem;
}

.noselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.MuiTable-stickyFooter {
  .MuiTableCell-footer {
    left: 0;
    bottom: 0;
    z-index: 2;
    position: sticky;
    font-size: 14px;
    font-weight: bold;
    background: #fafafa;
  }
}


.niquel-articulo-image {
  min-width: 30rem;
  min-height: 10rem;

  .img-not-found {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ececec;

    svg {
      font-size: 3rem;
      color: #AAA;
    }
  }

  .img-found {
    margin: auto;

    img {
      max-height: 300px;
    }
  }
}

.niquel-view-with-bottom-margin {
  margin-bottom: 6rem;
}

//Fix para el área de click del speed dial cuando está cerrado.
.MuiSpeedDial-actionsClosed {
  height: 0;
}
