.niquel-estadisticas-main {
  position: relative;
  .settings-btn {
    position: fixed;
    z-index: 1001;
    right: 2rem;
    top: 4rem;
  }

  .VentasSettingsDialog {
    .container {

    }
  }
}
