.niquel-pedidosMap-group {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1002;
}

.niquel-pedidosMap-notifications {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1002;
}

.niquel-pedidosMap-opciones {
  position: fixed !important;
  right: 1.5rem;
  bottom: 5.5rem;
  z-index: 1202;
  color: rgb(200, 200, 200);
}
