@import '../../assets/styles/colors';

.niquel-home {
  height: 100vh;

  .niquel-stat-card {
    min-width: 250px;
  }

  .line-chart {
    height: 300px;
    //width: 100%;
  }
}