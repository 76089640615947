@import "../../../assets/styles/colors";

.niquel-reactGrid {
  table {
    //colgroup {
    //  col {
    //    width: auto;
    //  }
    //  col:nth-child(1) {
    //    width: 300px !important;
    //  }
    //}

    tbody {
      tr {
        cursor: pointer;

        &:hover {
          background-color: $table-row-hover;
          div {
            background-color: transparent;
          }
        }

        td[class*="TableNoDataCell"] {
          border: none !important;
          background-color: #FFF !important;
          font-size: 13px !important;
          color: rgba(33, 33, 33, 0.7) !important;
          cursor: default !important;
        }
      }
    }
  }
}
