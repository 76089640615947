.niquel-pedidos-operation {
  .select {
    label {
      white-space: nowrap;
    }
  }

  .selectCondComercial {
    min-width: 9.5rem;
  }

  .inputBonificacion {
    width: 4.5rem;
  }

  .input {
    width: 8em;
  }

  .date {
    width: 6rem;
  }
}